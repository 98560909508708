var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scale-transition',[_c('BaseForm',{attrs:{"apiActions":"userroleunit/userroleunit.php","backRoute":'/userroleunitlist/unit/' + this.$route.params.pid,"newRecordTitle":"Nova veza osoba - uloga - org. jedinica","listRefreshAction":"userroleunit_list_needs_refresh","formData":[
      {
        title: 'VEZANI PODACI',
        rows: [
          {
            id: 1,
            fields: [
              {
                label: 'Osoba',
                type: 'user',
                name: 'user'
              },
              {
                label: 'Uloga',
                type: 'role',
                name: 'role'
              },
              {
                label: 'Org. jedinica',
                type: 'text',
                name: 'unit',
                disabled: true,
                initialValue: this.$route.params.pid
              }
            ]
          }
        ]
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }