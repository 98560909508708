<template>
  <v-scale-transition>
    <BaseForm
      apiActions="userroleunit/userroleunit.php"
      :backRoute="'/userroleunitlist/unit/' + this.$route.params.pid"
      newRecordTitle="Nova veza osoba - uloga - org. jedinica"
      listRefreshAction="userroleunit_list_needs_refresh"
      :formData="[
        {
          title: 'VEZANI PODACI',
          rows: [
            {
              id: 1,
              fields: [
                {
                  label: 'Osoba',
                  type: 'user',
                  name: 'user'
                },
                {
                  label: 'Uloga',
                  type: 'role',
                  name: 'role'
                },
                {
                  label: 'Org. jedinica',
                  type: 'text',
                  name: 'unit',
                  disabled: true,
                  initialValue: this.$route.params.pid
                }
              ]
            }
          ]
        }
      ]"
    ></BaseForm>
  </v-scale-transition>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";

export default {
  data() {
    return {};
  },
  components: {
    BaseForm
  }
};
</script>
